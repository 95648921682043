export const fetchRansomwareData = async () => {
  try {
    const response = await fetch(
      `https://raw.githubusercontent.com/joshhighet/ransomwatch/main/posts.json`
    );
    const data = await response.json();
    return Array.isArray(data) ? data : []; // Verifica que data sea un array
  } catch (error) {
    console.error("Error fetching ransomware data:", error);
    return [];
  }
};

export const fetchGroups = async () => {
  try {
    const response = await fetch(
      `https://raw.githubusercontent.com/joshhighet/ransomwatch/main/groups.json`
    );
    const data = await response.json();
    return Array.isArray(data) ? data : []; // Verifica que data sea un array
  } catch (error) {
    console.error("Error fetching groups data:", error);
    return [];
  }
};
