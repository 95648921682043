import React, { useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useData } from "../../dataContext";
import { useTranslation } from "react-i18next";
import TranslationToggle from "../../components/TranslationToggle";
import { useTable } from "react-table";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import "./groupDetails.css";

const GroupDetailsAndAttacks = () => {
  const { t } = useTranslation();
  const { name } = useParams();
  const { data, groups } = useData();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const group = useMemo(() => {
    if (!Array.isArray(groups) || !name) return null;
    return groups.find(group => group.name?.toLowerCase() === name.toLowerCase());
  }, [groups, name]);

  const groupData = useMemo(() => {
    if (!group) return [];
    return data.filter((item) => item.group_name?.toLowerCase() === name.toLowerCase());
  }, [data, name, group]);

  const filteredGroupData = useMemo(() => {
    return groupData.filter((attack) => attack.post_title.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [groupData, searchTerm]);

  const attackTimelineData = useMemo(() => {
    const timeline = {};
    groupData.forEach(attack => {
      const date = new Date(attack.discovered).toLocaleDateString();
      if (!timeline[date]) {
        timeline[date] = 0;
      }
      timeline[date]++;
    });
    return Object.entries(timeline).map(([date, count]) => ({ date, count }));
  }, [groupData]);

  const chartOptions = useMemo(() => ({
    chart: {
      backgroundColor: '#ffffff',
      borderRadius: 12,
      style: {
        fontFamily: "'Fira Code', monospace"
      }
    },
    title: {
      text: t("actividad_de_ataques_a_lo_largo_del_tiempo"),
      style: {
        color: '#333',
        fontSize: '22px',
      }
    },
    xAxis: {
      categories: attackTimelineData.map(point => point.date),
      title: {
        text: t("fecha"),
        style: {
          color: '#333',
          fontSize: '16px',
        }
      },
      labels: {
        style: {
          color: '#333',
          fontSize: '12px',
        }
      },
      lineColor: '#85c1e9'
    },
    yAxis: {
      title: {
        text: t("numero_de_ataques"),
        style: {
          color: '#333',
          fontSize: '16px',
        }
      },
      labels: {
        style: {
          color: '#333',
          fontSize: '12px',
        }
      },
      gridLineColor: '#cccd'
    },
    series: [{
      name: t("ataques"),
      data: attackTimelineData.map(point => point.count),
      color: '#000000'
    }],
    plotOptions: {
      series: {
        marker: {
          radius: 4,
          fillColor: '#6495ed'
        }
      }
    },
    tooltip: {
      backgroundColor: '#ffffff',
      borderColor: '#ccc',
      style: {
        color: '#333',
        fontSize: '12px',
      }
    },
  }), [attackTimelineData, t]);

  const locationColumns = useMemo(() => [
    {
      Header: t("titulo"),
      accessor: "title",
      Cell: ({ value }) => value || "null",
    },
    {
      Header: t("url"),
      accessor: "fqdn"
    },
    {
      Header: t("ultima_vez_scrapeado"),
      accessor: "lastscrape",
    },
    {
      Header: t("disponible"),
      accessor: "available",
      Cell: ({ value }) => value ? '✅' : '❌',
    }
  ], [t]);

  const locationsData = useMemo(() => group?.locations || [], [group]);

  const {
    getTableProps: getLocationsTableProps,
    getTableBodyProps: getLocationsTableBodyProps,
    headerGroups: locationsHeaderGroups,
    rows: locationsRows,
    prepareRow: prepareLocationsRow,
  } = useTable({ columns: locationColumns, data: locationsData });

  const attackColumns = useMemo(() => [
    {
      Header: t("titulo"),
      accessor: "post_title",
    },
    {
      Header: t("fecha_de_publicacion"),
      accessor: "discovered",
    }
  ], [t]);

  const {
    getTableProps: getAttacksTableProps,
    getTableBodyProps: getAttacksTableBodyProps,
    headerGroups: attacksHeaderGroups,
    rows: attacksRows,
    prepareRow: prepareAttacksRow,
  } = useTable({ columns: attackColumns, data: filteredGroupData });

  if (!group) {
    return <p>{t("cargando_datos_o_nombre_del_grupo_no_proporcionado")}</p>;
  }

  return (
    <div className="container group-details">
      <div>
      <TranslationToggle />
      <button className="back-button" onClick={() => navigate("/")}>
        &larr; {t("volver_al_inicio")}
      </button>
      <h1>{group.name}</h1>
      <p><strong>{t("descripcion")}:</strong> {group.meta ? group.meta : t("no_descripcion")}</p>

      <h2>{t("ubicaciones")}</h2>
      <table {...getLocationsTableProps()} className="locations-table">
        <thead>
          {locationsHeaderGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getLocationsTableBodyProps()}>
          {locationsRows.map(row => {
            prepareLocationsRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {group.profile.length > 0 && (
        <>
          <h2>{t("referencias")}</h2>
          <ul>
            {group.profile.map((d, index) => (
              <li key={index}><a href={d}>{d}</a></li>
            ))}
          </ul>
        </>
      )}
      </div>

      <hr />
      <div className="attack-container">
        <h2>
          {t("ataques_del_grupo")}: <span style={{ textTransform: "capitalize" }}>{name}</span>
        </h2>

        <HighchartsReact highcharts={Highcharts} options={chartOptions} />

        <input
          type="text"
          className="search-input"
          placeholder={t("buscar_ataque_por_titulo")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <table {...getAttacksTableProps()} className="attacks-table">
          <thead>
            {attacksHeaderGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getAttacksTableBodyProps()}>
            {attacksRows.map(row => {
              prepareAttacksRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupDetailsAndAttacks;
