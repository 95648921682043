import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchRansomwareData,fetchGroups } from "./api";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]); 

  useEffect(() => {
    const loadData = async () => {
      const rawData = await fetchRansomwareData();
      setData(rawData);

      const groupData = await fetchGroups(); 
      setGroups(groupData);
    };
    loadData();
  }, []);

  return (
    <DataContext.Provider value={{ data, groups }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
